import React from "react";
import { IoIosHeart } from "react-icons/io";
import { motion } from "framer-motion";
import { brandsList } from "../../jsonFiles/brandsList";
import { useNavigate } from "react-router-dom";
import SubHeading from "../global/SubHeading";
import { Heading2, Heading3, Heading5, Heading6 } from "../global/Headings";
import { Paper } from "@mui/material";

export default function OurBrands() {
  const navigate = useNavigate();

  return (
    <div className="mt-10 sm:mt-10" id="FMCG Company">
      <div className="">
        <Heading2 title="FMCG Company" />

        <Paper className="p-4 mt-12" sx={{ borderRadius: 3 }}>
          <div className="md:grid grid-cols-6 flex flex-col  gap-6 justify-center items-center">
            <div className="order-2 md:order-none col-span-4">
              <Heading5 title="Asha Industries" />
              <div className="mt-4 text-sm text-gray-400">
                <p>
                  At Asha Industries, we are a leading FMCG company dedicated to
                  enhancing the lives of a large and diverse consumer base
                  across India by delivering exceptional products that
                  seamlessly combine quality, innovation, and affordability.
                  From pantry essentials to personal care items, our diverse and
                  ever-expanding portfolio is thoughtfully designed to cater to
                  the dynamic and evolving needs of households in today’s
                  fast-paced world.
                </p>
              </div>
            </div>
            <div className="order-1 md:order-none col-span-2">
              <img
                src="/assets/svg/asha-ind-logo.svg"
                className="h-32 mx-auto"
                alt="fewmax_logo"
              />
            </div>
          </div>
        </Paper>
      </div>
      <div className="mt-10">
        <Heading6 title="Our Products" />
      </div>

      <div className="mt-6 grid sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-center flex-wrap">
        {brandsList?.map((item, index) => (
          <OurBrandCard
            key={index}
            brandName={item?.brandName}
            slogan={item?.slogan}
            stabilizedYear={item?.stabilizedYear}
            likes={item?.likes}
            brandLogo={item?.brandLogo}
            onClick={() =>
              navigate("/brandDetails", { state: { brandId: item?._id } })
            }
          />
        ))}
      </div>
    </div>
  );
}

const OurBrandCard = ({
  stablisedYear,
  likes,
  brandName,
  slogan,
  brandLogo,
  imageStyle = "object-fill",
  onClick,
}) => {
  return (
    <motion.div
      onClick={onClick}
      className="p-6 group cursor-pointer bg-white rounded-xl border-t border-borderColor shadow-lg shadow-shadowColor hover:bg-gradient-to-tr from-secondary via-rose-600 to-primary hover:text-white hover:shadow-shadowColor hover:shadow-xl duration-500 animate-gradient-move"
      initial={{ opacity: 0.7, scale: 1, y: 80 }}
      whileInView={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 0.01 }}
      viewport={{ once: true }}
    >
      <div className="cursor-pointer">
        <div className="overflow-hidden rounded-xl w-full h[200px]">
          <img
            className={`w-full h[200px] rounded-xl hover:scale-105 duration-700 border-2 border-white ${imageStyle}`}
            alt="logo"
            src={brandLogo}
          />
        </div>

        <div className="text-primary group-hover:text-white">
          {/* Apply group-hover here */}
          <div className="mt-4 flex items-center justify-between text-secondary group-hover:text-white">
            <p className="font-semibold">Since {stablisedYear}</p>
            {/* Removed individual hover:text-white */}
            <p className="text-secondary font-semibold flex items-center gap-2 group-hover:text-white">
              {/* Added group-hover */}
              <IoIosHeart /> {likes}
            </p>
          </div>
          <div className="">
            <h2 className="mt-6 text-lg cursor-pointer font-normal leading-8 duration-500 group-hover:text-white text-rose-600">
              {brandName}
            </h2>
            <h2 className="mt-2 text-base font-normal cursor-pointer leading-8 duration-500 group-hover:text-white text-rose-600">
              {slogan}
            </h2>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
