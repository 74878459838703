import React from "react";

export function Heading1({ title, className }) {
  return (
    <div>
      <h1
        className={`${className} bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent inline-block xl:text-7xl text-5xl font-bold`}
      >
        {title}
      </h1>
    </div>
  );
}

export function Heading2({ title, className }) {
  return (
    <div className="">
      <h2
        className={`${className} pb-1 bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent inline-block xl:text-6xl text-4xl font-semibold`}
      >
        {title}
      </h2>
    </div>
  );
}

export function Heading3({ title, className }) {
  return (
    <div>
      <h3
        className={`${className} bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent inline-block xl:text-5xl text-3xl font-medium`}
      >
        {title}
      </h3>
    </div>
  );
}

export function Heading4({ title, className }) {
  return (
    <div>
      <h4
        className={`${className} bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent inline-block xl:text-4xl text-2xl font-normal`}
      >
        {title}
      </h4>
    </div>
  );
}

export function Heading5({ title, className }) {
  return (
    <div>
      <h5
        className={`${className} bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent inline-block xl:text-3xl text-xl font-light`}
      >
        {title}
      </h5>
    </div>
  );
}

export function Heading6({ title, className }) {
  return (
    <div>
      <h6
        className={`${className} bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent inline-block xl:text-2xl text-lg font-extralight`}
      >
        {title}
      </h6>
    </div>
  );
}
